import {
	Flex,
	Label,
	RangePicker,
} from '@opsdti-global-component-library/amgen-design-system';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { filtersKey } from '../../../constants';
import { useValidationModal } from '../../../hooks/use-validation-modal';
import { fetchRequestLogTableData } from '../../../services/request-log-table-data';
import { AppContext } from '../../../state/app-context';
import {
	OutletContextType,
	RequestLogModalSettings,
	RequestLogTableData,
	ValidationStatusType,
} from '../../../types';
import sortData from '../../../utils/sort-data';
import FilterComponent from '../../filter-component';
import RequestLogModal from '../../modals/request-log-modal';
import ValidationModal from '../../modals/validation-modal';
import NotificationsLoader from '../../reusable/notifications-loader';
import RequestLogTable from '../../tables/request-log-table';
import '../index.scss';

type RequestLogFiltersType = {
	start_date: string;
	end_date: string;
	workstream: string;
	approval_status: string;
	delivery_status: string;
};

const RequestLogTabContent: React.FC = () => {
	const { requestId } = useParams();
	const { tableContentLoader, setTableContentLoader } = useContext(AppContext);
	const { setRequestsCount } = useOutletContext<OutletContextType>();
	const [openRequestLogModal, setOpenRequestLogModal] =
		useState<boolean>(false);
	const [requestLogModalSetting, setRequestLogModalSetting] =
		useState<RequestLogModalSettings>(RequestLogModalSettings.DEFAULT);
	const [filterSelection, setFilterSelection] = useState<RequestLogFiltersType>(
		{
			start_date: '',
			end_date: '',
			workstream: '',
			approval_status: '',
			delivery_status: '',
		}
	);
	const [requestData, setRequestData] = useState<{
		original: Array<RequestLogTableData> | undefined;
		filtered: Array<RequestLogTableData> | undefined;
	}>({
		original: undefined,
		filtered: undefined,
	});
	const [isFetching, setIsFetching] = useState<boolean>(false);
	const [uniqueWorkstreams, setUniqueWorkstreams] = useState<string[]>([]);
	const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
	const [filteredDataBackup, setFilteredDataBackup] = useState<
		Array<RequestLogTableData>
	>([]);
	const [disablePolling, setDisablePolling] = useState<boolean>(false);
	const { validationModalConfig, openValidationModal, closeValidationModal } =
		useValidationModal();

	const navigate = useNavigate();

	const isValidRequestId = useMemo(() => {
		return (
			requestId &&
			requestData.original &&
			requestData.original
				.map((request) => request?.event_log_id)
				.includes(requestId)
		);
	}, [requestId, requestData]);

	const isLoading = useMemo(() => {
		return isFetching || tableContentLoader;
	}, [isFetching, tableContentLoader]);

	const handleOpenRequestLogModal = (modalState: RequestLogModalSettings) => {
		setRequestLogModalSetting(modalState);
		setOpenRequestLogModal(true);
	};

	const handleRequestClick = (request_id: string) => {
		navigate(`${request_id}`);
	};

	const handleFilterSelection = (attribute: string, value: string) => {
		const updatedFiltersSelection = { ...filterSelection };

		updatedFiltersSelection[filtersKey[attribute]] = value;

		setFilterSelection(updatedFiltersSelection);
	};

	const handleRequestModalDismiss = (resetRoute = true) => {
		setOpenRequestLogModal(false);
		if (resetRoute) {
			navigate('/subscriptions/request-log');
		}
	};

	const fetchRequestLogTableDatafn = useCallback(() => {
		fetchRequestLogTableData({})
			.then((response) => {
				const correctedResponse = response.map((res) => {
					if (res?.approval_status === 'None required') {
						return { ...res, approval_status: 'None Required' };
					}
					return { ...res };
				});
				setUniqueWorkstreams([
					...new Set(correctedResponse.map((res) => res?.workstream)),
				]);
				setRequestData({
					original: correctedResponse,
					filtered: correctedResponse,
				});
				setRequestsCount(
					correctedResponse.filter(
						(res) => res.approval_status === 'Ready for Review'
					).length
				);
			})
			.catch((e) => console.log(e))
			.finally(() => {
				setIsFetching(false);
				setTableContentLoader(false);
				setIsFirstLoad(false);
			});
	}, [setRequestsCount, setTableContentLoader]);

	const handleFetchRequestLogTabledata = useCallback(() => {
		setIsFetching(true);
		fetchRequestLogTableDatafn();
	}, [fetchRequestLogTableDatafn]);

	const handleValidationModalDismiss = (
		resetRoute = true,
		status: ValidationStatusType = 'WARNING'
	) => {
		closeValidationModal();
		if (resetRoute) {
			navigate('/subscriptions/request-log');
		}
		// refetch the request log table data when closing a succeeded validation modal
		if (status === 'SUCCESS') {
			handleFetchRequestLogTabledata();
		}
	};

	// fetch the request log table data on page load or filter values change
	useEffect(() => {
		handleFetchRequestLogTabledata();
	}, [filterSelection, handleFetchRequestLogTabledata]);

	useEffect(() => {
		const appRoot = document && document?.getElementsByTagName('html')[0];
		if (!openRequestLogModal) {
			appRoot.style.overflowY = '';
		}
	}, [openRequestLogModal]);

	useEffect(() => {
		if (!isFirstLoad) {
			if (isValidRequestId) {
				handleOpenRequestLogModal(RequestLogModalSettings.DEFAULT);
			} else {
				navigate('/subscriptions/request-log');
			}
		}
	}, [isValidRequestId, isFirstLoad, navigate]);

	// poll request log table data every 10 seconds on first fetch completion
	useEffect(() => {
		// disaple polling if any of the modals is opened
		const fetchRequestLogTableDataInterval = setInterval(() => {
			if (
				!isLoading &&
				!openRequestLogModal &&
				!validationModalConfig.open &&
				!disablePolling
			) {
				fetchRequestLogTableDatafn();
			} else {
				return () => clearInterval(fetchRequestLogTableDataInterval);
			}
		}, 10000);

		return () => clearInterval(fetchRequestLogTableDataInterval);
	}, [
		fetchRequestLogTableDatafn,
		isLoading,
		openRequestLogModal,
		validationModalConfig.open,
		disablePolling,
	]);

	// Filter data on data refresh or filter values change
	useEffect(() => {
		let filteredData: Array<RequestLogTableData> = [
			...(requestData.original ?? []),
		];
		filteredData = filteredData.filter((data: RequestLogTableData) => {
			if (
				filterSelection.start_date &&
				new Date(data.request_date.split(' ')[0]) <
					new Date(filterSelection.start_date)
			) {
				return false;
			}
			if (
				filterSelection.end_date &&
				new Date(data.request_date.split(' ')[0]) >
					new Date(filterSelection.end_date)
			) {
				return false;
			}
			if (
				filterSelection.workstream &&
				filterSelection.workstream !== 'All' &&
				data.workstream !== filterSelection.workstream
			) {
				return false;
			}
			if (
				filterSelection.approval_status &&
				filterSelection.approval_status !== 'All' &&
				data.approval_status !== filterSelection.approval_status
			) {
				return false;
			}
			if (
				filterSelection.delivery_status &&
				filterSelection.delivery_status !== 'All' &&
				data.delivery_status !== filterSelection.delivery_status
			) {
				return false;
			}
			return true;
		});

		setFilteredDataBackup([...filteredData]);

		// on filter change, the tableContentloader context variable is set to true
		setTableContentLoader(false);
	}, [
		filterSelection,
		requestData.original,
		// tableContentLoader,
		setTableContentLoader,
	]);

	useEffect(() => {
		if (!isLoading) {
			setRequestData((prevData) => {
				return { ...prevData, filtered: filteredDataBackup };
			});
		}
	}, [isLoading, filteredDataBackup]);

	return (
		<>
			<div className="notifications-tab-content">
				<Flex
					className="notifications-table-controls"
					align="center"
					justify="space-between"
					gap={20}
					wrap="wrap"
				>
					<Flex className="notifications-table-left-controls" gap={8}>
						<Label>Date Range</Label>
						<RangePicker
							className="notifications-request-date-picker"
							popupClassName="notifications-date-popup"
							disabled={isLoading}
							onChange={(dates, dateStrings) => {
								setFilterSelection(
									(prevFilterSelection: RequestLogFiltersType) => {
										return {
											...prevFilterSelection,
											start_date: dateStrings[0],
											end_date: dateStrings[1],
										};
									}
								);
							}}
						/>
					</Flex>
					<FilterComponent
						header={'PAGE'}
						optionsList={[
							{ value: 'All', label: 'All' },
							...uniqueWorkstreams
								.map((workstream) => {
									return { value: workstream, label: workstream };
								})
								.sort((a, b) => sortData(a.label, b.label, 'ascend')),
						]}
						loading={isLoading}
						selectedValue={filterSelection?.workstream}
						handleFilterSelection={handleFilterSelection}
					/>
					<FilterComponent
						header={'APPROVAL STATUS'}
						optionsList={[
							{ value: 'All', label: 'All' },
							{ value: 'Ready for Review', label: 'Ready for Review' },
							{ value: 'None Required', label: 'None Required' },
							{ value: 'Denied', label: 'Denied' },
							{ value: 'Approved', label: 'Approved' },
							{ value: 'Expired', label: 'Expired' },
						]}
						loading={isLoading}
						selectedValue={filterSelection?.approval_status}
						handleFilterSelection={handleFilterSelection}
					/>
					<FilterComponent
						type="STATUS"
						header={'DELIVERY STATUS'}
						optionsList={[
							{ value: 'All', label: 'All' },
							{
								value: 'Pending',
								label: 'Pending',
							},
							{
								value: 'Processing',
								label: 'Processing',
							},
							{
								value: 'Cancelled',
								label: 'Cancelled',
							},
							{
								value: 'Failed',
								label: 'Failed',
							},
							{
								value: 'Succeeded',
								label: 'Succeeded',
							},
						]}
						loading={isLoading}
						selectedValue={filterSelection?.delivery_status}
						handleFilterSelection={handleFilterSelection}
					/>
				</Flex>
				{isFirstLoad ? (
					<NotificationsLoader />
				) : (
					<div className="notifications-table">
						<RequestLogTable
							data={requestData.filtered ?? []}
							loading={isLoading}
							onRequestClick={handleRequestClick}
						/>
					</div>
				)}
			</div>
			<RequestLogModal
				opened={openRequestLogModal}
				onModalDismiss={handleRequestModalDismiss}
				openValidationModal={openValidationModal}
				onCancelAdUsersModal={handleOpenRequestLogModal}
				requestLogModalSetting={requestLogModalSetting}
				requestToken={
					requestData.filtered
						? requestData?.filtered.filter(
								(request) => request?.event_log_id === requestId
						  )[0]?.token ?? ''
						: ''
				}
				disablePolling={setDisablePolling}
			/>
			<ValidationModal
				open={validationModalConfig.open}
				title={validationModalConfig.title}
				status={validationModalConfig.status}
				header={validationModalConfig.header}
				content={validationModalConfig.content}
				onModalDismiss={handleValidationModalDismiss}
				isRequestLog={true}
				setOpenPreview={() => {
					setOpenRequestLogModal(true);
					setRequestLogModalSetting(RequestLogModalSettings.DEFAULT);
				}}
				setOpenConfirm={openValidationModal}
			/>
		</>
	);
};

export default RequestLogTabContent;
