import { LoginCallback } from '@okta/okta-react';
import { ThemeProvider } from '@opsdti-global-component-library/amgen-design-system';
import { useContext, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import NotificationsRoot from '../../pages';
import ActivityLogPage from '../../pages/activity-log-page';
import AvailablePage from '../../pages/available-page';
import ManagePage from '../../pages/manage-page';
import RequestLogPage from '../../pages/request-log-page';
import SubscriptionsPage from '../../pages/subscriptions-page';
import { AppContext } from '../../state/app-context';
import {
	AdmissibleUserRoles,
	FetchNotificationResponse,
	SelectedSubscriber,
} from '../../types';
import AuthComponent from '../auth-component';
import RequiredAuthComponent from '../auth-component/required-auth';
import PageLayout from '../page-layout';

const NotificationsRoutes: React.FC = () => {
	const { userFullname, userId } = useContext(AppContext);
	const [isRefetchingNotifications, setIsRefetchingNotifications] =
		useState<boolean>(false);
	const [tableContentLoader, setTableContentLoader] = useState<boolean>(false);
	const [selectedSubscriber, setSelectedSubscriber] =
		useState<SelectedSubscriber>({
			name: `${userFullname.split(', ')[1]} ${userFullname.split(', ')[0]}`,
			is_delegatee: false,
			delegate_user_id: '',
		});
	const [queryDataKey, setQueryDataKey] = useState<
		'NotificationList' | 'SubscriptionList' | undefined
	>(undefined);
	const [originalList, setOriginalList] = useState<FetchNotificationResponse[]>(
		[]
	);
	const [enablePolling, setEnablePolling] = useState<boolean>(false);
	const [userRole, setUserRole] = useState<AdmissibleUserRoles>(undefined);

	const themeService = null;

	return (
		<AuthComponent>
			<ThemeProvider themeService={themeService}>
				<PageLayout>
					<AppContext.Provider
						value={{
							userFullname: userFullname,
							userId: userId,
							isRefetchingNotifications: isRefetchingNotifications,
							setIsRefetchingNotifications,
							tableContentLoader: tableContentLoader,
							setTableContentLoader: setTableContentLoader,
							selectedSubscriber: selectedSubscriber,
							setSelectedSubscriber,
							queryDataKey: queryDataKey,
							setQueryDataKey: setQueryDataKey,
							originalList: originalList,
							setOriginalList,
							enablePolling: enablePolling,
							setEnablePolling,
							userRole: userRole,
							setUserRole,
						}}
					>
						<Routes>
							<Route path="/" element={<RequiredAuthComponent />}>
								<Route
									path=""
									element={<Navigate to="/subscriptions" />}
								></Route>
								<Route path="/subscriptions" element={<NotificationsRoot />}>
									<Route index element={<SubscriptionsPage />} />
									<Route path="available">
										<Route index element={<AvailablePage />} />
										<Route
											path="*"
											element={
												<Navigate to="/subscriptions/available" replace />
											}
										/>
									</Route>
									<Route path="manage-users">
										<Route index element={<ManagePage />} />
										<Route
											path="*"
											element={
												<Navigate to="/subscriptions/manage-users" replace />
											}
										/>
									</Route>
									<Route path="request-log/:requestId?">
										<Route index element={<RequestLogPage />} />
										<Route
											path="*"
											element={
												<Navigate to="/subscriptions/request-log" replace />
											}
										/>
									</Route>
									<Route path="activity-log" element={<ActivityLogPage />} />
									<Route
										path="*"
										element={<Navigate to="/subscriptions" replace />}
									/>
								</Route>
							</Route>
							<Route path="/implicit/callback" element={<LoginCallback />} />
						</Routes>
					</AppContext.Provider>
				</PageLayout>
			</ThemeProvider>
		</AuthComponent>
	);
};
export default NotificationsRoutes;
