import { useEffect, useState } from 'react';
import { ActivityLog } from '@gitlab-rtsensing-activity-log/activity-logs-feature';
import { fetchActivityLogDropdowns } from '../../../services/activity-log-dropdown-options-data';
import { fetchActivityLogSummaryData } from '../../../services/activity-log-summary-data';
import { fetchActivityLogTimelineData } from '../../../services/activity-log-timeline-data';
import { snakeToCamel } from '../../../utils/snake-to-camel';
import './index.scss';

const defaultActivityLogFilters = {
	workstream: 'Prioritized Agenda',
	filter_list: [
		{
			filter_key: 'item_id',
			filter_value: 'All',
		},
		{
			filter_key: 'status',
			filter_value: 'All',
		},
		{
			filter_key: 'timeline',
			filter_value: 'Last 60 Days',
		},
	],
};

const ActivityLogPage = () => {
	const [aiSummary, setAiSummary] = useState<string>('');
	const [dropdownOptions, setDropdownOptions] = useState<any>({});
	const [activityLogTimeline, setActivityLogTimeline] = useState<any>([]);
	const [filtersSelection, setFiltersSelection] = useState<any>(
		defaultActivityLogFilters
	);
	const [isFetching, setIsFetching] = useState<boolean>(false);
	const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);

	const onFilterValueChange = (obj) => {
		setFiltersSelection((prevPayload) => ({
			...prevPayload,
			filter_list: obj.map((item) => ({
				filter_key: item.filterKey,
				filter_value: item.filterValue,
			})),
		}));
	};

	// Data populate with default parameters
	useEffect(() => {
		fetchActivityLogDropdowns({ workstream: 'Prioritized Agenda' }).then(
			(response) => {
				const camelResponse = snakeToCamel(response);
				setDropdownOptions(camelResponse);
			}
		);
	}, []);

	// Data populate with updated parameters
	useEffect(() => {
		setIsFetching(true);
		Promise.all([
			fetchActivityLogSummaryData(filtersSelection),
			fetchActivityLogTimelineData(filtersSelection),
		])
			.then((responses) => {
				const [aiSummaryResponse, timelineResponse] = responses;
				const camelTimelineResponse = timelineResponse.map((item) => {
					return snakeToCamel(item);
				});
				setAiSummary(
					(aiSummaryResponse
						? aiSummaryResponse
						: '') as React.SetStateAction<string>
				);
				setActivityLogTimeline(
					camelTimelineResponse && camelTimelineResponse.length > 0
						? camelTimelineResponse
						: []
				);
			})
			.catch((error) => console.log('error', error))
			.finally(() => {
				setIsFetching(false);
				setIsFirstLoad(false);
			});
	}, [filtersSelection]);

	return (
		<div>
			<ActivityLog
				title="Recent Updates"
				workstream="Prioritized Agenda"
				timelineSize={3}
				hideAISummary={false}
				aiSummary={aiSummary}
				timelineData={activityLogTimeline}
				dropdownMenuOptions={dropdownOptions}
				onFilterChange={(obj) => onFilterValueChange(obj)}
				loading={isFetching && isFirstLoad}
				drawerLoading={isFetching}
			/>
		</div>
	);
};

export default ActivityLogPage;
