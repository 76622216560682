import { useContext } from 'react';
import {
	Button,
	Table,
	Text,
	Flex,
} from '@opsdti-global-component-library/amgen-design-system';
import { AppContext } from '../../../state/app-context';
import { getChannelLabel } from '../../../utils/get-channel-label';
import sortData from '../../../utils/sort-data';
import { FetchNotificationResponse, SubscriptionType } from '../../../types';
import './index.scss';

interface NotificationsTableProps {
	notificationsList: Array<FetchNotificationResponse>;
	type: SubscriptionType;
	tableClassName: string;
	setNotificationData: (data: FetchNotificationResponse) => void;
	setOpen: (val: boolean) => void;
}

const NotificationsTable = ({
	notificationsList,
	type,
	tableClassName,
	setNotificationData,
	setOpen,
}: NotificationsTableProps) => {
	const { isRefetchingNotifications, tableContentLoader } =
		useContext(AppContext);

	const getsubscribedChannels = (
		typeData: SubscriptionType,
		selectedRow: FetchNotificationResponse
	) => {
		return typeData === SubscriptionType.UNSUBSCRIBED
			? getChannelLabel(selectedRow?.channel_id)
			: selectedRow?.subscribed_channels?.length > 0
			? getChannelLabel(selectedRow?.subscribed_channels)
			: 'N/A';
	};

	const handleButtonClick = (data: FetchNotificationResponse) => {
		setNotificationData(data);
		setOpen(true);
	};

	const getActionButton = (selectedRow: FetchNotificationResponse) => {
		return selectedRow.is_processing ? (
			<Flex justify="center">
				<Button text="PENDING" type="link" disabled />
			</Flex>
		) : type === SubscriptionType.SUBSCRIBED ||
		  selectedRow.is_subscribed ||
		  selectedRow.is_ad_group ? (
			<Flex justify="center">
				<Button
					text="EDIT"
					onClick={() => {
						handleButtonClick(selectedRow);
					}}
					type="link"
				/>
			</Flex>
		) : (
			<Flex justify="center">
				<Button
					style={{ width: '100%' }}
					text="Subscribe"
					onClick={() => {
						handleButtonClick(selectedRow);
					}}
					type="secondary"
				/>
			</Flex>
		);
	};

	return (
		<>
			<Table
				className={tableClassName}
				bordered
				columns={[
					{
						dataIndex: 'workstream',
						key: 'workstream',
						sortDirections: ['descend', 'ascend', 'descend'],
						defaultSortOrder: 'ascend',
						sorter: (a, b, sortOrder) =>
							sortData(a?.workstream, b?.workstream, sortOrder),
						title: (
							<Text strong className="subscriptions-table-column">
								PAGE
							</Text>
						),
						width: '123px',
					},
					{
						dataIndex: 'name',
						key: 'name',
						sortDirections: ['descend', 'ascend', 'descend'],
						title: (
							<Text strong className="subscriptions-table-column">
								NAME
							</Text>
						),
						sorter: (a, b, sortOrder) => sortData(a?.name, b?.name, sortOrder),
						ellipsis: true,
						width: '278px',
					},
					{
						dataIndex: 'metric_details',
						key: 'metric_details',
						sortDirections: ['descend', 'ascend', 'descend'],
						sorter: (a, b, sortOrder) =>
							sortData(a?.metric_details, b?.metric_details, sortOrder),
						render: (_, selectedRow) => selectedRow.metric_details ?? 'N/A',
						title: (
							<Text strong className="subscriptions-table-column">
								DETAIL
							</Text>
						),
						width: '256px',
						ellipsis: true,
					},
					{
						dataIndex: 'arche_type',
						key: 'arche_type',
						sortDirections: ['descend', 'ascend', 'descend'],
						sorter: (a, b, sortOrder) =>
							sortData(a?.arche_type, b?.arche_type, sortOrder),
						title: (
							<Text strong className="subscriptions-table-column">
								TRIGGER
							</Text>
						),
						width: '153px',
					},
					{
						dataIndex: 'subscribed_channels',
						key: 'subscribed_channels',
						sortDirections: ['descend', 'ascend', 'descend'],
						sorter: (a, b, sortOrder) =>
							sortData(
								getsubscribedChannels(type, a),
								getsubscribedChannels(type, b),
								sortOrder
							),
						render: (_, selectedRow) =>
							getsubscribedChannels(type, selectedRow),
						title: (
							<Text strong className="subscriptions-table-column">
								CHANNEL
							</Text>
						),
						width: '151px',
					},
					{
						dataIndex: 'is_subscribed',
						key: 'is_subscribed',
						sortDirections: ['descend', 'ascend', 'descend'],
						sorter: (a, b) => +a?.is_subscribed - +b?.is_subscribed,
						render: (_, data1) => getActionButton(data1),
						title: (
							<Text strong className="subscriptions-table-column">
								ACTION
							</Text>
						),
						width: '106px',
						fixed: 'right',
					},
				]}
				loading={isRefetchingNotifications || tableContentLoader}
				dataSource={notificationsList}
				style={{
					width: '100%',
				}}
				scroll={{
					x: '1067px',
				}}
				pagination={{
					position: ['none', 'bottomRight'],
					showSizeChanger: true,
					showTotal: (total: number, range: [number, number]) =>
						`${range[0]}-${range[1]} of ${total} items`,
				}}
			/>
		</>
	);
};

export default NotificationsTable;
