import {
	Label,
	Modal,
	Text,
	theme,
} from '@opsdti-global-component-library/amgen-design-system';
import { useEffect, useState } from 'react';
import { getADUsers } from '../../../../services/get-ad-users';
import { ADUsersDataType, RequestLogModalSettings } from '../../../../types';
import sortData from '../../../../utils/sort-data';
import NotificationsBackButton from '../../../reusable/notification-back-button';
import NotificationsLoader from '../../../reusable/notifications-loader';
import ApprovalRecipientsTable from '../request-log-modal-recipients/approval-recipients-table';
import './index.scss';

interface AdUsersModalProps {
	isOpen: boolean;
	requestStatus: string;
	adGroup: string;
	onAdUsersModalDismiss: () => void;
	onCancelAdUsersModal: (modalState: RequestLogModalSettings) => void;
}

const AdUsersModal = ({
	isOpen,
	requestStatus,
	adGroup,
	onAdUsersModalDismiss,
	onCancelAdUsersModal: handleOpenRequestLogModal,
}: AdUsersModalProps) => {
	const { token } = theme;

	const [adUsersList, setADUsersList] = useState<ADUsersDataType[] | undefined>(
		undefined
	);
	const [isFetching, setIsFetching] = useState<boolean>(false);
	const [isError, setIsError] = useState<boolean>(false);

	const adminHeader = [
		{
			dataIndex: 'user_name',
			key: 'fullname',
			title: (
				<Text strong className="subscriptions-table-column">
					USER NAME
				</Text>
			),
			width: '142px',
			sortDirections: ['descend', 'ascend', 'descend'],
			defaultSortOrder: 'ascend',
			sorter: (a, b, sortOrder) =>
				sortData(a?.user_name, b?.user_name, sortOrder),
		},
		{
			dataIndex: 'user_id',
			key: 'loginname',
			title: (
				<Text strong className="subscriptions-table-column">
					USER ID
				</Text>
			),
			width: '86px',
		},
		{
			dataIndex: 'position',
			key: 'position',
			title: (
				<Text strong className="subscriptions-table-column">
					POSITION
				</Text>
			),
			ellipsis: true,
		},
	];

	useEffect(() => {
		if (isOpen) {
			setIsFetching(true);
			setIsError(false);
			getADUsers({}, adGroup)
				.then((response) => setADUsersList(response))
				.catch((e) => {
					console.log(e);
					setIsError(true);
					setADUsersList(undefined);
				})
				.finally(() => setIsFetching(false));
		}
	}, [adGroup, isOpen]);

	return (
		<Modal
			open={isOpen}
			onCancel={onAdUsersModalDismiss}
			className="subscriptions-ad-users-modal"
			classNames={{ body: 'notifications-ad-users-modal-body-content' }}
			title={
				requestStatus
					? `Notification - ${
							requestStatus === 'Ready for Review' ? 'Review' : requestStatus
					  }`
					: '\n'
			}
			fixedHeight={true}
			height={588}
		>
			{isFetching ? (
				<NotificationsLoader />
			) : (
				<>
					<NotificationsBackButton
						text="BACK TO AD GROUPS"
						onClick={() => {
							handleOpenRequestLogModal(RequestLogModalSettings.SECONDARY);
							onAdUsersModalDismiss();
						}}
						style={{
							marginBottom: '14px',
						}}
					/>
					{!isError ? (
						<>
							<Label
								style={{
									color: token?.colorText,
									marginBottom: '12px',
								}}
							>
								{`${adGroup} - ${adUsersList ? adUsersList.length : '0'} ${
									adUsersList && adUsersList.length === 1 ? 'user' : 'users'
								}`}
							</Label>
							<ApprovalRecipientsTable
								header={adminHeader}
								data={adUsersList ?? []}
							/>
						</>
					) : (
						<b>Failed To Fetch Data</b>
					)}
				</>
			)}
		</Modal>
	);
};

export default AdUsersModal;
