import {
	Flex,
	Label,
	StatusIcon,
	Text,
	theme,
} from '@opsdti-global-component-library/amgen-design-system';
import NotificationPreview from '../../../reusable/notification-preview';
import { convertUTCToPST } from '../../../../utils/convert-utc-to-pst';
import { notAvailable } from '../../../../constants';
import { RequestLogModalData } from '../../../../types';
import './index.scss';

interface RequestLogModalPreviewProps {
	requestData: RequestLogModalData;
}

const RequestLogModalPreview = ({
	requestData,
}: RequestLogModalPreviewProps) => {
	const { token } = theme;

	return (
		<div
			className={`request-log-preview-container ${
				requestData?.approval_status === 'Ready for Review' ? 'small' : 'large'
			}`}
		>
			<Flex className="request-log-modal-preview-container">
				<Flex vertical gap={4} className="request-log-modal-preview-content">
					<Label style={{ color: token?.colorTextDescription }}>
						APPROVAL STATUS
					</Label>
					<Text>{requestData?.approval_status}</Text>
				</Flex>
				<Flex vertical gap={3} className="request-log-modal-preview-content">
					<Label style={{ color: token?.colorTextDescription }}>
						DELIVERY STATUS
					</Label>
					<Flex align="center" gap={4}>
						{notAvailable.includes(requestData?.delivery_status) ||
						notAvailable.includes(requestData?.delivery_count) ? (
							<></>
						) : (
							<StatusIcon
								status={
									['Pending', 'Processing', 'Cancelled'].includes(
										requestData?.delivery_status
									)
										? 'missing'
										: requestData?.delivery_status === 'Succeeded'
										? 'success'
										: 'error'
								}
								width={20}
								height={20}
							/>
						)}
						<Text>
							{['Succeeded', 'Failed'].includes(requestData?.delivery_status)
								? requestData?.delivery_count === 'NA'
									? 'Not Available'
									: requestData?.delivery_count
								: requestData?.delivery_status}
						</Text>
					</Flex>
				</Flex>
			</Flex>

			<Flex className="request-log-modal-preview-container">
				<Flex vertical gap={4} className="request-log-modal-preview-content">
					<Label style={{ color: token?.colorTextDescription }}>
						Request Date
					</Label>
					<Flex gap={12}>
						<Text>
							{convertUTCToPST(requestData?.request_date).substring(
								0,
								convertUTCToPST(requestData?.request_date).indexOf(' ')
							)}
						</Text>
						<Text>
							{convertUTCToPST(requestData?.request_date).substring(
								convertUTCToPST(requestData?.request_date).indexOf(' ') + 1
							)}
						</Text>
					</Flex>
				</Flex>
				<Flex vertical gap={4} className="request-log-modal-preview-content">
					<Label style={{ color: token?.colorTextDescription }}>
						{requestData?.date_type.split('_').join(' ')}
					</Label>
					<Flex gap={12}>
						{notAvailable.includes(requestData?.date) ? (
							<Text>Not Available</Text>
						) : (
							<>
								<Text>
									{convertUTCToPST(requestData?.date).substring(
										0,
										convertUTCToPST(requestData?.date).indexOf(' ')
									)}
								</Text>
								<Text>
									{convertUTCToPST(requestData?.date).substring(
										convertUTCToPST(requestData?.date).indexOf(' ') + 1
									)}
								</Text>
							</>
						)}
					</Flex>
				</Flex>
			</Flex>

			{!['Ready for Review', 'None Required', 'Expired'].includes(
				requestData?.approval_status
			) && (
				<Flex vertical gap={4} className="request-log-modal-preview-content">
					<Label style={{ color: token?.colorTextDescription }}>
						Reviewed By
					</Label>
					<Text>
						{requestData?.reviewer_name === 'NA'
							? 'Not Available'
							: requestData?.reviewer_name}
					</Text>
				</Flex>
			)}

			<div
				className="notifications-division-line"
				style={{
					borderBottom: `1px solid ${token?.colorBorderSecondary}`,
				}}
			></div>

			<Flex vertical gap={4} className="request-log-modal-preview-content">
				<Label style={{ color: token?.colorTextDescription }}>Event name</Label>
				<Text>{requestData.event_name}</Text>
			</Flex>
			<Flex vertical gap={4} className="request-log-modal-preview-content">
				<Label style={{ color: token?.colorTextDescription }}>
					Event description
				</Label>
				<Text>{requestData.event_description}</Text>
			</Flex>
			<Flex className="request-log-modal-preview-container">
				<Flex vertical gap={4} className="request-log-modal-preview-content">
					<Label style={{ color: token?.colorTextDescription }}>Trigger</Label>
					<Text>{requestData?.trigger}</Text>
				</Flex>
				<Flex vertical gap={4} className="request-log-modal-preview-content">
					<Label style={{ color: token?.colorTextDescription }}>
						Frequency
					</Label>
					<Text>
						{requestData?.frequency[0].toUpperCase() +
							requestData?.frequency.slice(1).toLowerCase()}
					</Text>
				</Flex>
			</Flex>
			<NotificationPreview
				previewDetails={requestData?.delivery_messages?.messages.map(
					(message) => {
						// if we have multiple messages for a single delivery chanel, couple them in a single body
						// and wrap each individual message with a p tag
						const templateContent = message?.message
							.map((content) => {
								const body = content.replace(
									/<\/?(!DOCTYPE html|html|body>|head)[^>]*>/gi,
									''
								);
								if (body) {
									let updatedBody = body;
									// update the anchor tags in the message so user is redirected to a new tab
									// first get all opening anchor tags
									const hyperlinks = [...updatedBody.matchAll(/<a.*?>/g)];
									hyperlinks?.forEach((hyperlink) => {
										const isTargetBlank =
											hyperlink.indexOf('target="_blank"') > 0;
										// for each opening anchor tag if it's missing the target="_blank" property add it
										if (!isTargetBlank) {
											const targetBlankHyperlink =
												hyperlink[0].slice(0, -1) + ' target="_blank"' + '>';
											updatedBody = updatedBody.replace(
												hyperlink[0],
												targetBlankHyperlink
											);
										}
									});

									return `<div>${updatedBody}</div>`;
								} else {
									return '';
								}
							})
							.join();

						return {
							channel: message?.channel.includes('teams')
								? 'MS Teams'
								: 'Email',
							templateContent: `<!DOCTYPE html><html><body>${
								templateContent.length !== 0
									? templateContent
									: '<b>No Message Available</b>'
							}</body></html>`,
						};
					}
				)}
				title={requestData?.notification_name}
				style={{ marginTop: '4px' }}
				isTemplate={false}
			/>
		</div>
	);
};

export default RequestLogModalPreview;
