import { Loader } from '@gitlab-rtsensing/component-library';
import { Flex } from '@opsdti-global-component-library/amgen-design-system';

const NotificationsLoader = () => {
	return (
		<Flex
			justify="center"
			align="center"
			flex={1}
			className="subscriptions-loader"
		>
			<Loader />
		</Flex>
	);
};

export default NotificationsLoader;
