import { UserRole, ValidationModalConfigType } from '../types';

/** export error messages */
export * from './error-messages';

export const paginationItems = 10;
export const items = 5;
export const activityLogAllowedIds = [
	'lson',
	'sedukull',
	'pnag01',
	'atiwar16',
	'ssinha09',
	'csharm08',
	'rkhiln01',
	'rparnami',
];
export const activityLogAllowedEnvs = ['development', 'test'];
export const manageTabAllowedRoles: string[] = [UserRole.ADMIN];
export const requestLogTabAllowedRoles: string[] = [
	UserRole.ADMIN,
	UserRole.APPROVER,
];
export const deliveryStatusMapping = {
	Pending: 'missing',
	Processing: 'missing',
	Cancelled: 'missing',
	Succeeded: 'success',
	Failed: 'error',
};
export const filtersKey = {
	'START DATE': 'start_date',
	'END DATE': 'end_date',
	PAGE: 'workstream',
	'APPROVAL STATUS': 'approval_status',
	'DELIVERY STATUS': 'delivery_status',
};

export const defaultValidationModalConfig: ValidationModalConfigType = {
	open: false,
	title: '',
	status: 'SUCCESS',
	header: '',
	content: '',
};

export const notAvailable = ['NA', 'Not Available'];
